// =================================
// ## HEADER
// =================================

#header-panel {
    padding: 1.1rem 0;
    border-bottom: 8px solid #dea022;
    background: url('img/header-bg-gradient.png') repeat-x;
    // background-size: contain;
    background-size: auto 150%;
    margin-bottom: 1.5rem;

    .brand-logo {
        .image {
            max-width: 220px;
        }
    }
}

// =================================
// ## FOOTER
// =================================

#footer {
    display: flex;
    flex-direction: column;
    background: #1c1a17;
    color: #7c5e35;
    padding: 0;
    margin-top: 0;

    &::before {
        display: block;
        content: '';
        flex-grow: 1;
        background: #f9fAfb;
    }

    >.container {
        padding: 2.8em;
    }

    .rwp {
        padding: 2rem;

        p, h3 {
            text-align: center;
        }
    }
}

#footer-bar {
    padding: 0.5rem 0;
    margin-bottom: 0;
    background: #0c0c0b;

    address {
        margin-bottom: 0;
    }

    >.container {
        display: flex;
        flex-wrap: wrap;
        font-size: 0.8rem;

        >.copyright {
            flex-grow: 1;
        }

        >.goldenbird {
            align-items: flex-end;
        }
    }

    .copyright {
        display: flex;
        align-items: center;

        img {
            padding-right: 1rem;
        }
    }

    .goldenbird {
        a {
            display: inline-block;
            transition: all 0.3s ease;

            img {
                transition: all 0.3s ease;
                filter: grayscale(50%);
            }

            &:hover {
                img {
                    filter: grayscale(0);
                }
            }
        }

        img {
            max-width: 50px;
        }
    }

    @include media-breakpoint-up(sm) {
        .copyright {
            img {
                margin: -0.5rem 0;
            }
        }

        .goldenbird {
            a {
                // display: inline-block;
                margin: -0.5rem 0;

                &:hover {
                    background: #1c1a17;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .container {
            // display: block;

            >div {
                // width: 50%;
                display: block;

                img, span {
                    display: block;
                }

                a, img {
                    margin: 0;
                }
            }
        }

        .goldenbird {
            // text-align: right;
        }
    }

    @include media-breakpoint-down(xs) {
        .goldenbird {
            span {
                display: none;
            }
        }
    }
}
