@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
// @import "bootstrap/scss/root";
// @import "bootstrap/scss/reboot";
// @import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
// @import "bootstrap/scss/code";

// ===
// GRID
// ===
// @import "bootstrap/scss/grid";

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

@if $enable-grid-classes {
    .container:not(.ui) {
        @include make-container();
        @include make-container-max-widths();
    }
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but with 100% width for
// fluid, full width layouts.

@if $enable-grid-classes {
    .container-fluid {
        @include make-container();
    }
}

// Row
//
// Rows contain and clear the floats of your columns.

@if $enable-grid-classes {
    .row {
        @include make-row();
    }

    // Remove the negative margin from default .row, then the horizontal padding
    // from all immediate children columns (to prevent runaway style inheritance).
    .no-gutters {
        margin-right: 0;
        margin-left: 0;

        > .col,
        > [class*="col-"] {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
    @include make-grid-columns();
}

// ===

// @import "bootstrap/scss/tables";
// @import "bootstrap/scss/forms";
// @import "bootstrap/scss/buttons";
// @import "bootstrap/scss/transitions";
// @import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
// @import "bootstrap/scss/input-group";
// @import "bootstrap/scss/custom-forms";
// @import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar";
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/jumbotron";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/media";
// @import "bootstrap/scss/list-group";
// @import "bootstrap/scss/close";
// @import "bootstrap/scss/modal";
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/utilities";
// @import "bootstrap/scss/print";

// ===

@import './variables';
@import './mixins';

@import './layout';
@import './product_list';
